import { DefaultApiParams, memberAPI, orderAPI } from '@api';
import { ChangeIcon, OrderSection, SharedButton, Trash, ViewIcon } from '@components';
import {
  DEFAULT_CUSTOMER_FILTER_ITEM,
  DEFAULT_SELLER_FILTER_ITEM,
  INITIAL_PAGE,
  MAIN_THEME_DATA,
  MAX_ITEM_PER_PAGE,
  ORDER_STATUS_NUMBER,
  ORDER_STATUS_TEXT,
  PATH_ORDER,
  ROLES,
} from '@configs';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  AutocompleteItem,
  CustomerFilterItem,
  CustomerRoot,
  Order,
  OrderAutocompleteData,
  OrderColumn,
  OrderData,
  OrderFilterPayload,
  OrderRoot,
  RootResponse,
  Seller,
  SellerItem,
  SellerRoot,
  UpdateOrderStatusPayload,
} from '@interfaces';
import {
  selectApp,
  selectAuth,
  setCurrentCustomer,
  setCurrentDateRange,
  setCurrentOrderStatus,
  setCurrentPage,
  setCurrentSeller,
  setLoading,
  setTableLoading,
  useAppDispatch,
  useAppSelector,
} from '@redux';
import { LogApp, convertPriceToInt, toVND } from '@utils';
import { Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { debounce } from 'lodash';
import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { styled } from 'styled-components';
import { useImmer } from 'use-immer';
import * as yup from 'yup';

const schema = yup.object().shape({
  money_paid: yup.string().required('Vui lòng nhập số tiền khách trả'),
});
const accountantSchema = yup.object().shape({
  reason: yup.string().required('Vui lòng nhập lí do từ chối'),
});
export const CustomTooltip = ({
  children,
  message,
  isButtonDisabled,
}: {
  children: JSX.Element;
  message: string;
  isButtonDisabled: boolean;
}) => {
  if (isButtonDisabled) return <Tooltip title={message}>{children}</Tooltip>;
  return children;
};
export const OrderModule = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { role } = useAppSelector(selectAuth);
  const isAccountant = role === ROLES.ACCOUNTANT;
  const isSeller = role === ROLES.SELLER;
  const [modalData, setModalData] = useImmer({
    opened: false,
    id: '',
    isPayModal: true,
  });
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setError,
    clearErrors,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    // mode: 'onChange',
    resolver: yupResolver(!modalData.isPayModal ? accountantSchema : schema),
  });
  const { currentPage, currentSeller, currentCustomer, currentOrderStatus, currentDateFilter } =
    useAppSelector(selectApp);
  const [orders, setOrders] = useImmer<OrderData>({
    limit: 0,
    count: 0,
    data: [],
  });
  const [orderPayload, setOrderPayload] = useImmer<DefaultApiParams & OrderFilterPayload>({
    page: currentPage ?? INITIAL_PAGE,
    keyword: '',
    size: 10,
    to_date: currentDateFilter?.[1] ?? null,
    from_date: currentDateFilter?.[0] ?? null,
    seller_id: currentSeller?.value || null,
    customer_id: currentCustomer?.value || null,
    status: currentOrderStatus ? Number(currentOrderStatus) : null,
  });
  const [autocompleteData, setAutocompleteData] = useImmer<OrderAutocompleteData>({
    customers: [],
    sellers: [],
  });
  const getButtonStatus = (status: number) => {
    if (isAccountant) {
      return (
        status === ORDER_STATUS_NUMBER.WAITING_FOR_ACCEPT ||
        status === ORDER_STATUS_NUMBER.APPROVED ||
        status === ORDER_STATUS_NUMBER.CANCEL ||
        status === ORDER_STATUS_NUMBER.RECEIVED
      );
    }
    return (
      status === ORDER_STATUS_NUMBER.WAITING_FOR_APPROVE ||
      status === ORDER_STATUS_NUMBER.CANCEL ||
      status === ORDER_STATUS_NUMBER.RECEIVED
    );
  };
  const getTooltipMessage = (status: number, msg = '') => {
    if (isAccountant) {
      if (status === ORDER_STATUS_NUMBER.WAITING_FOR_ACCEPT)
        return 'Đơn hàng cần được xác nhận bởi seller trước khi phê duyệt';
      if (status === ORDER_STATUS_NUMBER.CANCEL) return `Lí do: ${msg}`;
      return 'Đã phê duyệt';
    } else {
      if (status === ORDER_STATUS_NUMBER.WAITING_FOR_APPROVE) return 'Đã xác nhận';
      return ORDER_STATUS_TEXT[status];
    }
  };
  const getButtonText = (status: number) => {
    if (isAccountant) {
      if (
        status === ORDER_STATUS_NUMBER.WAITING_FOR_ACCEPT ||
        status === ORDER_STATUS_NUMBER.WAITING_FOR_APPROVE
      )
        return 'Phê duyệt';
      return ORDER_STATUS_TEXT[status];
    } else {
      if (status === ORDER_STATUS_NUMBER.WAITING_FOR_ACCEPT) return 'Xác nhận';
      if (status === ORDER_STATUS_NUMBER.WAITING_FOR_APPROVE) return 'Đã xác nhận';
      if (status === ORDER_STATUS_NUMBER.APPROVED)
        return ORDER_STATUS_TEXT[ORDER_STATUS_NUMBER.DELIVERING];
      if (status === ORDER_STATUS_NUMBER.DELIVERING)
        return ORDER_STATUS_TEXT[ORDER_STATUS_NUMBER.RECEIVED];
      return ORDER_STATUS_TEXT[status];
    }
  };
  const columns: ColumnsType<OrderColumn> = useMemo(() => {
    return [
      {
        title: 'STT',
        dataIndex: 'no',
        key: 'no',
        render: (text, record, index) => index + currentPage * Number(orderPayload?.size) + 1,
      },
      { title: 'Mã đơn hàng', dataIndex: 'code', key: 'code' },
      { title: 'Khách hàng', dataIndex: 'customer_name', key: 'customer_name' },
      { title: 'Nguời bán hàng', dataIndex: 'seller_name', key: 'seller_name' },
      { title: 'Tổng tiền', dataIndex: 'total_price', key: 'total_price' },
      {
        title: 'Trạng thái',
        dataIndex: 'order_status',
        key: 'order_status',
        render: (_, val) => ORDER_STATUS_TEXT[val.order_status],
      },
      {
        title: 'Ngày đặt hàng',
        dataIndex: 'created_at',
        key: 'created_at',
      },
      {
        title: 'Hành động',
        dataIndex: '',
        key: 'x',
        render: (value: any) => {
          const isDisabled = getButtonStatus(value.order_status);
          return (
            <StyledEditBtn>
              <CustomTooltip
                message={getTooltipMessage(value.order_status, value?.reason)}
                isButtonDisabled={isDisabled}
              >
                <SharedButton
                  disabled={isDisabled}
                  onClick={() => {
                    value.onPay();
                  }}
                  className={`edit-btn ${
                    value.order_status === ORDER_STATUS_NUMBER.CANCEL ? 'btn-delete' : 'btn-edit'
                  }`}
                  backgroundColor={MAIN_THEME_DATA.mainColor}
                  prevIcon={<ChangeIcon size={20} color={'white'} />}
                  textColor="white"
                  // text={isAccountant ? 'Phê duyệt' : 'Thanh toán'}
                  text={getButtonText(value.order_status)}
                />
              </CustomTooltip>
              {value.order_status !== ORDER_STATUS_NUMBER.CANCEL && (
                <SharedButton
                  onClick={() => {
                    value.onDeny();
                  }}
                  disabled={value.order_status !== ORDER_STATUS_NUMBER.WAITING_FOR_ACCEPT}
                  className="edit-btn btn-delete mx-3"
                  backgroundColor={MAIN_THEME_DATA.mainColor}
                  prevIcon={<Trash size={20} color={'white'} />}
                  textColor="white"
                  text={'Huỷ'}
                />
              )}
              {/* <SharedButton
                textColor="white"
                // backgroundColor={MAIN_THEME_DATA.mainColor}
                className="edit-btn btn-edit mx-3"
                prevIcon={<ViewIcon size={20} color="white" />}
                text="Chi tiết"
                onClick={() => {
                  value.onEdit();
                }}
              /> */}
            </StyledEditBtn>
          );
        },
      },
      {
        title: 'Chi tiết',
        dataIndex: '',
        key: 'y',
        render: (value: any) => {
          return (
            <StyledEditBtn>
              <SharedButton
                textColor="white"
                // backgroundColor={MAIN_THEME_DATA.mainColor}
                className="edit-btn btn-edit mx-3"
                prevIcon={<ViewIcon size={20} color="white" />}
                text="Chi tiết"
                onClick={() => {
                  value.onEdit();
                }}
              />
            </StyledEditBtn>
          );
        },
      },
    ];
  }, [currentPage]);
  const getOrders = async () => {
    try {
      dispatch(setTableLoading(true));
      setOrders({ ...orders, data: [] });
      const res: OrderRoot = await orderAPI.getOrders({
        ...orderPayload,
        keyword: orderPayload.keyword?.trim(),
        seller_id: orderPayload.seller_id ? orderPayload.seller_id : null,
      });
      const newResponse: OrderColumn[] = res.data.orders.map((item, index) => {
        return {
          no: index + 1,
          key: item.id,
          code: item.code,
          customer_name: item.customer.customer_name,
          seller_name: item.seller.brand_name,
          order_status: item.status,
          total_price: toVND(item.total_money),
          created_at: moment(item.created_at).format('DD/MM/YYYY'),
          onEdit: () => {
            navigate(`${PATH_ORDER}/${item.id}`);
          },
          onPay: () => {
            if (isAccountant) {
              onOpen(item);
            } else onUpdateStatus(item, item.status);
          },
          onDeny: () => {
            onOpenDenyModal(item);
          },
          reason: item?.reason || '',
        };
      });
      setOrders({
        limit: MAX_ITEM_PER_PAGE,
        count: res.data.count_order,
        data: newResponse,
      });
    } catch (error) {
    } finally {
      dispatch(setTableLoading(false));
    }
  };
  const onModalInteract = (value: boolean) => {
    setModalData((draft) => {
      draft.opened = value;
    });
  };

  const onClose = () => {
    onModalInteract(false);
    reset({ money_paid: '' });
  };
  const onOpen = (item: Order) => {
    setModalData((draft) => {
      draft.id = item.id;
      draft.opened = true;
    });
    setValue('total_money', item.total_money);
  };
  const onOpenDenyModal = (item: Order) => {
    setModalData((draft) => {
      draft.id = item.id;
      draft.opened = true;
      draft.isPayModal = false;
    });
  };
  const onUpdateStatus = (item: Order, status: number) => {
    switch (status) {
      case ORDER_STATUS_NUMBER.WAITING_FOR_ACCEPT:
        onUpdateOrder(item.id, ORDER_STATUS_NUMBER.WAITING_FOR_APPROVE);
        break;
      case ORDER_STATUS_NUMBER.APPROVED:
        onUpdateOrder(item.id, ORDER_STATUS_NUMBER.DELIVERING);
        break;
      case ORDER_STATUS_NUMBER.DELIVERING:
        onUpdateOrder(item.id, ORDER_STATUS_NUMBER.RECEIVED);
        break;
      default:
        break;
    }
  };
  const onUpdateOrder = async (id: string, status: number, payload?: UpdateOrderStatusPayload) => {
    try {
      dispatch(setLoading(true));
      const res = await orderAPI.payOrder(id, status, payload);
      toast.success('Cập nhật thành công', {
        position: 'top-right',
        autoClose: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: 'light',
      });
      await getOrders();
    } catch (err: any) {
    } finally {
      dispatch(setLoading(false));
    }
  };
  const onSubmit = handleSubmit((value) => {
    const payload: UpdateOrderStatusPayload = {
      money_paid: convertPriceToInt(value.money_paid),
    };
    onUpdateOrder(modalData.id, ORDER_STATUS_NUMBER.APPROVED, payload);
    onClose();
  });
  const onDeny = handleSubmit(
    (value) => {
      const payload: UpdateOrderStatusPayload = {
        reason: value.reason,
      };
      onUpdateOrder(modalData.id, ORDER_STATUS_NUMBER.CANCEL, payload);
      onClose();
    },
    (err) => {
      LogApp(err, 'ndh');
    },
  );

  const onPageChange = (num: number) => {
    LogApp(num);
    dispatch(setCurrentPage(num));
    setOrderPayload({ ...orderPayload, page: num });
  };
  const getAutocompleteData = async () => {
    try {
      const sellerRes: RootResponse<SellerItem[]> = await memberAPI.getAllSellerItems();
      const customerRes: CustomerRoot = await memberAPI.getCustomers({ all: true });
      setAutocompleteData((draft) => {
        draft.customers = customerRes.data.customers.map((item) => {
          return {
            label: item.full_name,
            value: item.id,
            item,
          };
        });
        draft.sellers = sellerRes.data.map((item) => {
          return {
            label: item.name,
            value: item.id,
            item,
          };
        });
      });
    } catch (error) {}
  };
  const onCustomerChange = (item: AutocompleteItem<CustomerFilterItem>) => {
    setOrderPayload((draft) => {
      draft.customer_id = item.value;
      draft.page = 0;
    });
    if (item.value) dispatch(setCurrentCustomer(item));
    else
      dispatch(
        setCurrentCustomer({
          label: '',
          value: '',
          item: DEFAULT_CUSTOMER_FILTER_ITEM,
        }),
      );
    dispatch(setCurrentPage(0));
  };
  const onSellerChange = (item: AutocompleteItem<SellerItem>) => {
    setOrderPayload((draft) => {
      draft.seller_id = item.value;
      draft.page = 0;
    });
    if (item.value) dispatch(setCurrentSeller(item));
    else
      dispatch(
        setCurrentSeller({
          label: '',
          value: '',
          item: DEFAULT_SELLER_FILTER_ITEM,
        }),
      );
    dispatch(setCurrentPage(0));
  };
  const onDateChange = (value: string[]) => {
    setOrderPayload((draft) => {
      draft.from_date = value[0];
      draft.to_date = value[1];
      draft.page = 0;
    });
    if (value.filter((item) => item !== '').length > 0) dispatch(setCurrentDateRange(value));
    else dispatch(setCurrentDateRange([]));
    dispatch(setCurrentPage(0));
  };
  const onStatusChange = (value: string) => {
    setOrderPayload((draft) => {
      if (value === 'null') draft.status = null;
      else draft.status = Number(value);
      draft.page = 0;
    });
    dispatch(setCurrentOrderStatus(value));
    dispatch(setCurrentPage(0));
  };
  const onKeyWordChange = debounce((value: string) => {
    setOrderPayload((draft) => {
      draft.keyword = value;
    });
  }, 600);
  useEffect(() => {
    getOrders();
  }, [orderPayload]);
  useEffect(() => {
    getAutocompleteData();
  }, []);

  return (
    <OrderSection
      register={register}
      errors={errors}
      control={control}
      columns={columns}
      orders={orders}
      onClose={onClose}
      modalData={modalData}
      handleSubmit={onSubmit}
      onDeny={onDeny}
      isAccountant={isAccountant}
      onPageChange={onPageChange}
      currentPage={orderPayload?.page || 0}
      autocompleteData={autocompleteData}
      onCustomerChange={onCustomerChange}
      onSellerChange={onSellerChange}
      onKeyWordChange={onKeyWordChange}
      orderPayload={orderPayload}
      onDateChange={onDateChange}
      onStatusChange={onStatusChange}
    />
  );
};
const StyledEditBtn = styled.div`
  display: flex;
  justify-content: center;
  .edit-btn {
    padding: 0.8rem 1.8rem;
    width: fit-content;
    align-self: center;
  }
`;
const StyledConfirmPopup = styled.div`
  .btn {
    padding: 0.4rem 0.3rem;
    /* max-width: 5rem; */
  }
`;
